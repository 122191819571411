<template>
  <div class="login-container">
    <my-nav-bar
      title="用户登录"
    />
    <div class="app-main-container">
      <van-cell-group>
        <van-field
          v-model="username"
          class="login-container__field"
          center
          label="用户名"
          placeholder="请输入用户名"
          clearable
        >
          <van-icon slot="left-icon" class-prefix="mhj-icon" name="yonghuming" />
        </van-field>
        <van-field
          v-model="password"
          class="login-container__field"
          center
          left-icon="smile-o"
          label="密码"
          type="password"
          placeholder="请输入密码"
          clearable
        >
          <van-icon slot="left-icon" class-prefix="mhj-icon" name="mima" />
        </van-field>
        <van-field
          v-model="code"
          class="login-container__field"
          center
          label="验证码"
          placeholder="请输入验证码"
          type="number"
          clearable
        >
          <van-icon slot="left-icon" class-prefix="mhj-icon" name="yanzhengma" />
          <template #button>
            <img class="login-container__captcha" :src="captchaData.img" @click="getCaptchat">
          </template>
        </van-field>
        <van-cell icon="shop-o" title="门店" :value="storeName" is-link center @click="showStores = true" />
      </van-cell-group>
      <div class="remember">
        <van-checkbox v-model="remember" icon-size="18px">记住密码</van-checkbox>
      </div>
      <van-button class="login-container__button" type="primary" round @click="login">登录</van-button>
    </div>
    <van-action-sheet v-model="showStores" :actions="stores" @select="handleSelectStore" />
  </div>
</template>

<script>
import md5 from 'js-md5'
import userApi, { getStores } from '@/api/user'
import captcha from '@/api/captcha'
import token from '@/utils/token'
import myNavBar from '@/components/my-nav-bar'

export default {
  name: 'LoginIndex',
  components: { myNavBar },
  data() {
    return {
      username: localStorage.getItem('username') || '',
      password: localStorage.getItem('password') || '',
      code: '',
      captchaData: {
        key: '',
        img: ''
      },
      remember: true,
      showStores: false,
      storeId: Number(localStorage.getItem('store_id')) || '',
      storeName: '',
      stores: []
    }
  },
  created() {
    this.getCaptchat()
    getStores().then(res => {
      this.stores = res.data
      if (this.storeId) {
        const store = this.stores.find(store => store.id === this.storeId)
        this.storeName = store.name
      }
    })
  },
  methods: {
    getCaptchat() {
      captcha.get().then(res => {
        this.captchaData = res.data
      })
    },
    login() {
      this.beginLoad()
      if (this.username.trim() === '') {
        this.fail('请输入用户名')
        return
      }
      if (this.password.trim() === '') {
        this.fail('请输入密码')
        return
      }
      if (this.code.trim() === '') {
        this.fail('请输入验证码')
        return
      }
      if (this.storeId === '') {
        this.fail('请选择门店')
        return
      }
      const data = {
        username: this.username,
        password: md5(this.password),
        key: this.captchaData.key,
        code: this.code,
        store_id: this.storeId
      }
      userApi.login(data).then(res => {
        if (res.code === 10001) {
          this.code = ''
          this.getCaptchat()
          return
        }

        token.set(res.data)
        userApi.info().then(res => {
          this.$store.commit('setUserInfo', res.data)
          localStorage.setItem('username', this.username)
          if (this.remember) {
            // 保存密码
            localStorage.setItem('password', this.password)
          } else {
            localStorage.removeItem('password')
          }
          this.endLoad()
          // redirect
          const redirect = this.$route.query.redirect
          if (redirect) {
            this.$router.back()
          } else {
            this.$router.push('/')
          }
        })
      })
    },
    handleSelectStore(action) {
      this.storeName = action.name
      this.storeId = action.id
      localStorage.setItem('store_id', this.storeId)
      this.showStores = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .login-container {
    padding: 15px;
    // height: calc(100vh - 30px);
    &__field {
      height: 60px;
    }
    &__button {
      width: 100%;
      margin-top: 20px;
    }
    &__captcha {
      width: 100px;
      height: 35px;
    }
  }
  .remember {
    padding: 10px;
  }
</style>
