import request from '@/utils/request'

export default {

  get() {
    return request({
      method: 'get',
      url: 'captcha'
    })
  }

}
