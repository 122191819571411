var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "login-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "用户登录"
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_c('van-cell-group', [_c('van-field', {
    staticClass: "login-container__field",
    attrs: {
      "center": "",
      "label": "用户名",
      "placeholder": "请输入用户名",
      "clearable": ""
    },
    model: {
      value: _vm.username,
      callback: function callback($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  }, [_c('van-icon', {
    attrs: {
      "slot": "left-icon",
      "class-prefix": "mhj-icon",
      "name": "yonghuming"
    },
    slot: "left-icon"
  })], 1), _c('van-field', {
    staticClass: "login-container__field",
    attrs: {
      "center": "",
      "left-icon": "smile-o",
      "label": "密码",
      "type": "password",
      "placeholder": "请输入密码",
      "clearable": ""
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }, [_c('van-icon', {
    attrs: {
      "slot": "left-icon",
      "class-prefix": "mhj-icon",
      "name": "mima"
    },
    slot: "left-icon"
  })], 1), _c('van-field', {
    staticClass: "login-container__field",
    attrs: {
      "center": "",
      "label": "验证码",
      "placeholder": "请输入验证码",
      "type": "number",
      "clearable": ""
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c('img', {
          staticClass: "login-container__captcha",
          attrs: {
            "src": _vm.captchaData.img
          },
          on: {
            "click": _vm.getCaptchat
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.code,
      callback: function callback($$v) {
        _vm.code = $$v;
      },
      expression: "code"
    }
  }, [_c('van-icon', {
    attrs: {
      "slot": "left-icon",
      "class-prefix": "mhj-icon",
      "name": "yanzhengma"
    },
    slot: "left-icon"
  })], 1), _c('van-cell', {
    attrs: {
      "icon": "shop-o",
      "title": "门店",
      "value": _vm.storeName,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        _vm.showStores = true;
      }
    }
  })], 1), _c('div', {
    staticClass: "remember"
  }, [_c('van-checkbox', {
    attrs: {
      "icon-size": "18px"
    },
    model: {
      value: _vm.remember,
      callback: function callback($$v) {
        _vm.remember = $$v;
      },
      expression: "remember"
    }
  }, [_vm._v("记住密码")])], 1), _c('van-button', {
    staticClass: "login-container__button",
    attrs: {
      "type": "primary",
      "round": ""
    },
    on: {
      "click": _vm.login
    }
  }, [_vm._v("登录")])], 1), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.stores
    },
    on: {
      "select": _vm.handleSelectStore
    },
    model: {
      value: _vm.showStores,
      callback: function callback($$v) {
        _vm.showStores = $$v;
      },
      expression: "showStores"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }